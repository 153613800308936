var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

$(".pageHeader .headerContent .header-search").clone().appendTo(".homeFeatures .homeFeature.homeBox2");

$(".footerBox[class*='ogos']").insertAfter(".pageFooterWrapper");

$(".homeIntroWrapper").insertBefore(".carousel");

$(".pageHeader .headerContent .tagline").clone().prependTo(".menuMain");


$('.pages-category-need-urgent-help .categoryFilterContent').change(function (){
  setTimeout(function(){
       $('.listContent').addClass('easeIn');
   }, 1200);
});